body {
    background-color: #F3F3F9;
}

hr {
    border-bottom: solid 1px #e67300;
    opacity: unset;
}

.custom-color {
    color: #e67300;
}

.custom-bg {
    background-color: #e67300;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-border {
    border: solid 1px #e67300;
}

.th-center tbody th {
    text-align: center;
}

.title-color {
    color: #e67300;
}

.info {
    background-color: #e67300;
    color: white;
    font-size: 18px;
    padding: 10px 20px;
}

.info a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}

.navbar {
    background-color: white !important;
    border-bottom: solid 2px #e67300;
    box-shadow: 0 2rem 2rem -1rem rgba(0, 0, 0, .175) !important;
}

.nav-link.active,
.nav-link:hover {
    color: white !important;
    background-color: #e67300;
    border-right: solid 1px #ffffff;
}

.navbar .nav-item .nav-link {
    color: #e67300;
    font-weight: bold;
}

.navbar .nav-item .dropdown-menu .dropdown-item {
    color: #e67300;
}

.navbar .nav-item .dropdown-menu {
    display: none;
}

.navbar .nav-item:hover .nav-link,
.navbar .nav-item .dropdown-menu .dropdown-item.active,
.navbar .nav-item .dropdown-menu .dropdown-item:hover {
    color: white !important;
    background-color: #e67300;
}

.navbar .nav-item:hover .dropdown-menu {
    display: block;
}

.navbar .nav-item .dropdown-menu {
    padding: 0;
    border: solid 1px #e67300;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
}

.navbar .nav-item .dropdown-menu .dropdown-item {
    padding: 8px 22px;
    border-bottom: solid 1px #e67300;
}

.navbar .nav-item .dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
}

.card {
    border: solid 1px #e67300;
}

.highlight .card-header {
    background-color: #e67300;
    color: white;
    cursor: pointer;
}

.home-page .banner {
    border-bottom: solid 2px #e67300;
}

.home-page .guru-dev-msg {
    background-color: #e67300;
    color: white;
}

.vision li {
    margin: 15px 0;
}

footer {
    background-color: #e67300;
    color: white;
    padding: 10px 20px;
    text-align: center;
}

.v-center {
    display: flex;
    align-items: center;
}

.mandatory-disclosure .custom-border thead tr:first-child,
.teaching-methodology .custom-border thead tr:first-child {
    background-color: #e67300;
    color: white;
}

.mandatory-disclosure .custom-border tbody tr th:first-child {
    color: #e67300;
    font-weight: bold;
}